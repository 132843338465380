import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="down" ref={ref} {...props} />;
});
export interface ModalAlertProps {
    open: boolean;
    title: string;
    message: string;
    onClose: React.MouseEventHandler<HTMLButtonElement>;
    color?: string;
    fontColor?: string;
}

const ModalAlert = ({ message, onClose, open, title, color = 'red', fontColor = 'white' }: ModalAlertProps) => {
    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={onClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle
                    style={{ background: color, color: fontColor }}
                >
                    {title}
                </DialogTitle>
                <DialogContent
                    style={{ background: color }}
                >

                    <DialogContentText
                        id="alert-dialog-slide-description"
                        style={{ color: fontColor }}
                    >
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions
                    style={{ background: color }}
                >
                    <Button onClick={onClose} style={{ color: fontColor }}>Ok</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
export default ModalAlert;