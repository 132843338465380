import { Grid, Skeleton, Stack } from '@mui/material';
import { AxiosError } from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setToken } from '../../../Reducers/Slices/LoginSlice';
import { ICCard } from '../../../Services/Comisiones/Interfaces/IHome';
import { CreateArray } from '../../../Utils/others';
import { ResumenPlanCompenzacionService } from '../../../Services/Comisiones/PlanCompenzacion';
import CCardResumen from '../../../Components/CCardResumen/CCardResumen';
import { ContextPlanCompenzacion } from '../context/Context';
import { initialDataFakeCards } from '../../../DataFake/DataFake';
import { StatusCode } from '../../../Services/StatusCode';
import { enqueueSnackbar } from 'notistack';

interface ResunMontemeSionProps {
    previewLoad: number;
}

export const ResumenPlanCompenzacion = ({ previewLoad }: ResunMontemeSionProps) => {
    const { ciclo, setCiclo } = useContext(ContextPlanCompenzacion);
    const nagivate = useNavigate();
    const dispatch = useDispatch();
    const updateToken = (token: boolean) => {
        dispatch(
            setToken({
                token: token
            })
        )
    }
    const [tarjetas, setTarjetas] = useState<ICCard[]>(initialDataFakeCards);
    const [carga, setCarga] = useState(false);
    const getTarjetas = async () => {
        try {
            setCarga(true);
            const { data } = await ResumenPlanCompenzacionService(ciclo.cicloId);
            if (data.status == 1) {
                setTarjetas(data.data);
                setCarga(false);
            }
            else {

            }
        } catch (error) {
            const { message, status } = StatusCode(error);
            enqueueSnackbar(message, { variant: 'error' });
            setTarjetas([]);
        }
    };
    useEffect(() => {
        getTarjetas();
    }, [ciclo.cicloId]);
    return (
        <>
            {
                tarjetas.map((tarjeta, i) => {
                    return (<CCardResumen
                        key={i}
                        icon={tarjeta.icon}
                        color={tarjeta.color}
                        descripcion={tarjeta.titulo}
                        monto={tarjeta.valor}
                        route={tarjeta.routeApp}
                        textToolip={''}
                        estado={carga}
                    />)
                })
            }
        </>
    );
}
