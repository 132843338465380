import { Box, Button, Card, Container, FormControl, Grid, Input, InputLabel, Stack, Typography } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles';
import { mdTheme } from '../../Router/routerApp';

interface Error404Props {

}

export const Error404 = () => {
    return (<>
        <ThemeProvider theme={mdTheme}>
            <main>
                <Box
                    sx={{
                        bgcolor: 'background.paper',
                        pt: 8,
                        pb: 6,
                    }}
                >
                    <Container maxWidth="md">
                        <Typography
                            component="h1"
                            variant="h3"
                            align="center"
                            color="text.primary"
                            gutterBottom
                        >
                            En mantenimiento
                        </Typography>
                        <Typography variant="h6" align="center" color="text.secondary" paragraph>
                            Sitio actualmente en mantenimiento intentelo nuevamente
                        </Typography>
                        <Stack
                            sx={{ pt: 4 }}
                            direction="row"
                            spacing={2}
                            justifyContent="center"
                        >
                            <Button size='small' href='/login' variant="contained">Volver </Button>
                        </Stack>
                    </Container>
                </Box>
            </main>
        </ThemeProvider>
    </>);
}
