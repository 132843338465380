import React, { useEffect, useState } from 'react'
import { ICiclo } from '../../../../Services/Comisiones/Interfaces/ICiclo';
import ModalCiclo from '../../../../Components/Modal/ModalCiclo';
import { CicloService } from '../../../../Services/Comisiones/CicloService';
import { initialStateCiclo } from '../../../../Utils/Ciclo';

interface CModalCicloProps {
    open: boolean;
    onClose: (value: ICiclo) => void;
}
const CModalCiclo = ({ open, onClose }: CModalCicloProps) => {
    const [ciclo, setCiclo] = useState<ICiclo[]>([]);
    const [carga, setCarga] = useState(true);
    const getTarjetas = async () => {
        try {
            const { data } = await CicloService();
            if (data.status == 1) {
                setCiclo(data.data.todosCiclos);
                setCarga(false);
            }
        } catch (error) {
            console.log(error)
        }
    };
    const [selectedValue, setSelectedValue] = useState<ICiclo>(initialStateCiclo);
    useEffect(() => {
        getTarjetas();
    }, []);
    return (
        <>
            <ModalCiclo
                listCiclo={ciclo}
                onClose={(e) => { onClose(e) }}
                open={open}
                selectedValue={selectedValue}
            />
        </>
    )
}

export default CModalCiclo
