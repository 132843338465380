import { DataTypeProvider, IntegratedFiltering, IntegratedSorting, SearchState, Sorting, SortingState } from '@devexpress/dx-react-grid';
import { Grid, SearchPanel, TableFixedColumns, TableHeaderRow, Toolbar, VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import { Paper, Chip } from '@mui/material';
import { useEffect, useState } from 'react'
import { Loading } from '../../../Components/Loading/Loading';
import { IDataGridEquipoPrimera } from '../../../Services/Comisiones/Interfaces/PlanCompenzacion';
import { FormatearNumero } from '../../../Utils/FormatoNumero';
interface DataGridEquiposProps {
    rows: IDataGridEquipoPrimera[],
    loading: boolean
}
export const DataGridEquipos = ({ rows, loading }: DataGridEquiposProps) => {

    const [tableColumnExtensions] = useState([
        { columnName: 'nro_equipo' },
        { columnName: 'nombre_completo', width: 400 },
        { columnName: 'generacion' },
        { columnName: 'rango', width: 180 },
        { columnName: 'monto_ventas' },
        { columnName: 'monto_pagar' }
    ]);
    const [columns] = useState([
        { name: 'nro_equipo', title: 'Nro equipo' },
        { name: 'nombre_completo', title: 'Nombre completo' },
        { name: 'generacion', title: 'Generacion' },
        { name: 'rango', title: 'Rango' },
        { name: 'monto_ventas', title: 'Monto ventas' },
        { name: 'monto_pagar', title: 'Monto a Pagar' },
    ]);
    const [sorting, setSorting] = useState<Sorting[]>([{ columnName: 'generacion', direction: 'asc' }]);

    const [dateColumnRango] = useState(['rango']);
    const columnRangoAlcanzado = ({ value }: any) => <Chip label={value} color='success' />;
    const CurrencyRangoAlcanzado = (props: any) => (
        <DataTypeProvider
            formatterComponent={columnRangoAlcanzado}
            {...props}
        />
    );

    const [dateColumnMontoVenta] = useState(['monto_ventas']);
    const columnRangoMontoVenta = ({ value }: any) => (`${FormatearNumero(value)} $US`);
    const CurrencyRangoMontoVenta = (props: any) => (
        <DataTypeProvider
            formatterComponent={columnRangoMontoVenta}
            {...props}
        />
    );

    const [dateColumnMontoPagar] = useState(['monto_pagar']);
    const columnMontoPagar = ({ value }: any) =>
    (<b style={{ color: 'darkblue' }}>
        {FormatearNumero(value)} $US
    </b>);
    const CurrencyMontoPagar = (props: any) => (
        <DataTypeProvider
            formatterComponent={columnMontoPagar}
            {...props}
        />
    );

    useEffect(() => {
        return () => {

        }
    }, [rows])
    return (
        <>
            <Paper style={{ position: 'relative' }}>
                <Grid
                    rows={rows}
                    columns={columns}
                >
                    <CurrencyRangoAlcanzado
                        for={dateColumnRango}
                    />
                    <CurrencyMontoPagar
                        for={dateColumnMontoPagar}
                    />
                    <CurrencyRangoMontoVenta
                        for={dateColumnMontoVenta}
                    />
                    <SearchState />
                    <IntegratedFiltering />
                    <Toolbar />
                    <SearchPanel />

                    <SortingState
                        sorting={sorting}
                        onSortingChange={setSorting}

                    />
                    <IntegratedSorting />
                    <VirtualTable columnExtensions={tableColumnExtensions} />
                    <TableHeaderRow showSortingControls />
                    <TableFixedColumns />
                </Grid>
                {loading && <Loading />}
            </Paper>
        </>
    )
}
