import { ICCard } from "../Services/Comisiones/Interfaces/IHome";

export const initialDataFakeCards: ICCard[] = [
    {
        titulo: '',
        valor: '',
        routeApp: '',
        color: '',
        icon: ''
    },
    {
        titulo: '',
        valor: '',
        routeApp: '',
        color: '',
        icon: ''
    },
    {
        titulo: '',
        valor: '',
        routeApp: '',
        color: '',
        icon: ''
    },
    {
        titulo: '',
        valor: '',
        routeApp: '',
        color: '',
        icon: ''
    }
]