import axios from "axios";
/*nuevo */
interface StatusCodeProps {
    status: number;
    message: string;
}
export const StatusCode = (e: unknown) => {
    var response: StatusCodeProps = {
        status: 0,
        message: ''
    };
    if (axios.isAxiosError(e)) {
        if (!e?.response) {
            response.status = 0;
            response.message = "Ocurrio un error";
        }
        else {
            console.log(e.response?.status)
            switch (e.response?.status) {
                case 404:
                    response.status = e.response?.status;
                    response.message = "Recurso no encontrado";
                    break;
                case 401:
                    response.status = e.response?.status;
                    response.message = "La session a expirado";
                    break;
                case 400:
                    response.status = e.response?.status;
                    response.message = "Error de validacion";
                    break;
                case 500:
                    response.status = e.response?.status;
                    response.message = "Ocurrio un error";
                    break;
                case 503:
                    response.status = e.response?.status;
                    response.message = "Sitio en mantenimiento";
                    break;
            }
        }
    }
    return response;
}