import { Navigate, Outlet } from "react-router-dom";
import { SideBar } from './Sidebar/sidebar';

interface Props {
    valid: boolean;
    children: React.ReactNode;
    redirrecTo: string;
    nombreCompleto: string;
    perfil: string;
}

export const ProtectorRoute = ({ children, valid, redirrecTo, nombreCompleto, perfil }: Props) => {
    //console.log('verificando',valid)
    if (!valid) {
        //console.log('a login')
        return (<Navigate to={redirrecTo} />)
    }
    else {
        //console.log('al sistema')
        return (<SideBar nombreCompleto={nombreCompleto} perfil={perfil}><Outlet /></SideBar>);
    }
}