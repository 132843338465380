import { createContext, useContext } from "react";
import { ICiclo } from "../../../Services/Comisiones/Interfaces/ICiclo";
import { initialStateCiclo } from "../../../Utils/Ciclo";

export type GlobalCiclo = {
    ciclo: ICiclo
    setCiclo: (c: ICiclo) => void
}
export const ContextPlanCompenzacion = createContext<GlobalCiclo>({
    ciclo: initialStateCiclo, // set a default value
    setCiclo: () => { },
})
export const useGlobalContext = () => useContext(ContextPlanCompenzacion);
