

export const CreateArray = (valor: number): number[] => {
    let loadInicial = [];
    let i = 0
    while (i < valor) {
        loadInicial.push(i);
        i++;
    }
    return loadInicial;
}

export const verificarModal = (value: string) => {
    const alert = localStorage.getItem(value);
    if (alert) {
        //console.log('data para evaluar', alert)
        return true;
    } else {
        //console.log('data para evaluar en nulo', alert)
        return false;
    }
}
export const DeleteAlertModal = () => {
    localStorage.removeItem('monteSion');
    localStorage.removeItem('PlanCompenzacion');
    localStorage.removeItem('ventaPersonal');
    localStorage.removeItem('ventaGrupo');
    localStorage.removeItem('ventaResidual');
}
export const NoViewAlertModal = (key: string, value: string) => {
    localStorage.setItem(key, value);
}
