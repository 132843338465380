import { ICiclo } from "../Services/Comisiones/Interfaces/ICiclo";

export const initialStateCiclo: ICiclo = {
    fechaFin: '',
    fechaInicio: '',
    mes: 'Cargando',
    colorMes: 'warning',
    messageAlerta: '',
    cicloId: 0,
    descripcion: 'Cargando...',
    message: 'Cargando...'
}