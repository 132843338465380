import { getDataDevice } from './GetDataNavigation'
import AXIOS from "axios";
export const asyncLocalStorage = {
    setItem: async function (key: any, value: any) {
        return localStorage.setItem(key, value);
    },
    getItem: async function (key: any) {
        return localStorage.getItem(key);
    },
    removeItem: async function (key: any) {
        return localStorage.removeItem(key);
    }
};

export interface AxiosResponseProps {
    status: number;
    statusText: string;
}
export const AxiosResponse = () => {
    AXIOS.interceptors.response.use(
        async (config) => {
            //validate consume archivos staticos
            switch (config.headers['content-type']) {
                case 'image/svg+xml':

                    break;

                default:
                    asyncLocalStorage.setItem('token', config.headers.authorization);
            }

            return config;
        },
        async (error) => {
            return {
                status: 0,
                message: 'Ocurrio un error',
                data: null
            }
        
        },
    );
}

export const AxiosRequest = async () => {
    AXIOS.interceptors.request.use(
        async (config) => {
            config.headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${await asyncLocalStorage.getItem('token')}`,
                'browserName': getDataDevice().browserName,
                'browserVersion': getDataDevice().browserVersion,
                'osName': getDataDevice().osName
            };
            return config;
        }
    );
}
