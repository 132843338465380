import { DataTypeProvider, IntegratedFiltering, IntegratedSorting, SearchState, Sorting, SortingState } from '@devexpress/dx-react-grid';
import { Grid, SearchPanel, TableFixedColumns, TableHeaderRow, Toolbar, VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import { Paper, Chip } from '@mui/material';
import  { useContext, useEffect, useState } from 'react'
import { Loading } from '../../../Components/Loading/Loading';
import { DataGridEquipoServices } from '../../../Services/Comisiones/PlanCompenzacion';
import { IDataGridEquipoPrimera } from '../../../Services/Comisiones/Interfaces/PlanCompenzacion';
import { ContextPlanCompenzacion } from '../context/Context';

export const DataGridEquipoPrimeraGeneracion= () => {
    const { ciclo, setCiclo } = useContext(ContextPlanCompenzacion);
    
    const [loading, setLoading] = useState(false);
    const [tableColumnExtensions] = useState([
        { columnName: 'nro_equipo' },
        { columnName: 'nombre_completo', width: 400 },
        { columnName: 'generacion' },
        { columnName: 'rango' },
        { columnName: 'monto_ventas' },
        { columnName: 'porcentaje_liderazgo' }
    ]);
    const [columns] = useState([
        { name: 'nro_equipo', title: 'Nro equipo' },
        { name: 'nombre_completo', title: 'Nombre completo' },
        { name: 'generacion', title: 'Generacion' },
        { name: 'rango', title: 'Rango' },
        { name: 'monto_ventas', title: 'Monto ventas' },
        { name: 'porcentaje_liderazgo', title: 'Porcentaje liderazgo' },
    ]);
    const [rows, setRows] = useState<IDataGridEquipoPrimera[]>([]);
    const [sorting, setSorting] = useState<Sorting[]>([{ columnName: 'nro_equipo', direction: 'asc' }]);
    
    const [dateColumns] = useState(['rango']);
    const columnRangoAlcanzado = ({ value }: any) => <Chip label={value} color='success'/>;
    const CurrencyTypeProvider = (props: any) => (
        <DataTypeProvider
            formatterComponent={columnRangoAlcanzado}
            {...props}
        />
    );

    const loadDataGrid = async () => {
        try {
            const { data } = await DataGridEquipoServices(ciclo.cicloId);
            setRows(data.data.dataGridPrimeraGeneracion)
            setLoading(false);
            console.log('consultar datagrid')
        } catch (error) {
            setLoading(false)
        }
    };
    useEffect(() => {
        setLoading(true);
        loadDataGrid();
        return () => {

        }
    }, [ciclo.cicloId])
    return (
        <>
            <Paper style={{ position: 'relative' }}>
                <Grid
                    rows={rows}
                    columns={columns}
                >
                    <CurrencyTypeProvider
                        for={dateColumns}
                    />
                    <SearchState />
                    <IntegratedFiltering />
                    <Toolbar />
                    <SearchPanel />

                    <SortingState
                        sorting={sorting}
                        onSortingChange={setSorting}

                    />
                    <IntegratedSorting />
                    <VirtualTable columnExtensions={tableColumnExtensions} />
                    <TableHeaderRow showSortingControls />
                    <TableFixedColumns />
                </Grid>
                {loading && <Loading />}
            </Paper>
        </>
    )
}
