import axios from "axios";
import { AxiosRequest, AxiosResponse } from "../../Utils/Axios";
import { IResponseTodosICiclo } from "./Interfaces/ICiclo";
import { IResponseIVentaPersonal } from "./Interfaces/VentaPersonal";

AxiosRequest();
AxiosResponse();
export async function CicloService() {
    return await axios.get<IResponseTodosICiclo>(`${process.env.REACT_APP_API_COMISIONES}api/ciclo`);
}
export async function CicloMonteSionService() {
    return await axios.get<IResponseTodosICiclo>(`${process.env.REACT_APP_API_COMISIONES}api/ciclo/monte-sion`);
}
export async function CiclosVentaPersonal(cicloId: number = 0) {
    return await axios.get<IResponseTodosICiclo>(`${process.env.REACT_APP_API_COMISIONES}api/ciclo/venta-personal?=${cicloId}`);
}
export async function CicloVentaGrupo() {
    return await axios.get<IResponseTodosICiclo>(`${process.env.REACT_APP_API_COMISIONES}api/ciclo/ciclo-grupo`);
}
export async function CicloVentaResidual() {
    return await axios.get<IResponseTodosICiclo>(`${process.env.REACT_APP_API_COMISIONES}api/ciclo/ciclo-residual`);
}