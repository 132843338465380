import './App.css';
import { Navigation } from './Router/routerApp';
import * as echarts from "echarts";
import { BoliviaMaps } from './Utils/Bolivia'
import { useEffect } from 'react';

echarts.registerMap('MapaMundial', BoliviaMaps);
function App() {
  useEffect(() => {

  }, []);

  return (
    <div>
      <Navigation></Navigation>
    </div>
  );
}

export default App;
