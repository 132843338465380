import { Accordion, AccordionDetails, AccordionSummary, Grid, Skeleton, Stack, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useContext, useEffect, useState } from 'react';
import { DataGridEquipoTodosServices } from '../../../Services/Comisiones/PlanCompenzacion';
import { IDataEquipo } from '../../../Services/Comisiones/Interfaces/PlanCompenzacion';
import { DataGridEquipos } from './DataGridEquipo';
import { ContextPlanCompenzacion } from '../context/Context';

const LoadCard = () => {
    return (
        <Grid container item sm={12} sx={{ p: 1 }}  >
            <Grid container item lg={12} style={{ marginBottom: 25 }} >
                <Stack spacing={1} sx={{ minWidth: '100%' }} style={{ alignItems: "center" }} >
                    <Skeleton variant="rounded" sx={{ width: '100%' }} height={90} />
                </Stack>
            </Grid>
        </Grid>
    )
}

const CEquipo = () => {
    const { ciclo, setCiclo } = useContext(ContextPlanCompenzacion);
    const [loading, setLoading] = useState(false);
    const [equipos, setEquipos] = useState<IDataEquipo[]>([]);

    const loadDataGrid = async () => {
        try {
            const { data } = await DataGridEquipoTodosServices(ciclo.cicloId);
            setEquipos(data.data.equipos);
            setLoading(false);
        } catch (error) {
        }
    };
    useEffect(() => {
        setLoading(true);
        loadDataGrid();
        return () => {

        }
    }, [ciclo.cicloId])
    return (
        <div>
            {loading ? (<LoadCard />) :
                (
                    equipos.map((equipo, i) => {
                        return (
                            <Accordion key={i}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography sx={{ width: '33%', flexShrink: 0 }}>{equipo.descripcion}</Typography>
                                    <Typography sx={{ color: 'text.secondary' }} >
                                        {equipo.descripcionTotal}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {
                                        equipo.condicion == '' ? null :
                                            (
                                                <Typography variant='body2' sx={{ fontStyle: 'italic' }} color={'red'}>
                                                    {equipo.condicion}
                                                </Typography>
                                            )
                                    }
                                    <DataGridEquipos rows={equipo.dataGridEquipos} loading={loading} />
                                </AccordionDetails>
                            </Accordion>
                        )
                    })
                )}
        </div>
    )
}

export default CEquipo
