import { Alert, Backdrop, Button, Chip, CircularProgress, Grid, Typography } from '@mui/material';
import { CBreadcrumbs } from '../../Components/CBreadcrumbs'
import DashboardIcon from '@mui/icons-material/Dashboard';
import CEquipo from './components/CEquipo';
import { ResumenPlanCompenzacion } from './components/ResumenPlanCompenzacion';
import { DataGridEquipoPrimeraGeneracion } from './components/DataGridPrimerEquipo';
import { useEffect, useState } from 'react';
import CModalCiclo from './components/CModalCiclo/CModalCiclo';
import { ICiclo } from '../../Services/Comisiones/Interfaces/ICiclo';
import { IndexPlanCompenzacionService } from '../../Services/Comisiones/PlanCompenzacion';
import ModalAlert from '../../Components/Modal/ModalAlert';
import { ContextPlanCompenzacion } from './context/Context';
import { NoViewAlertModal, verificarModal } from '../../Utils/others';
import { initialStateCiclo } from '../../Utils/Ciclo';

const PlanCompenzacion = () => {
    const [onModalciclo, setOnModalCiclo] = useState(false);
    const [spinner, setSpinner] = useState(true);
    const handleClose = (value: ICiclo) => {
        setOnModalCiclo(false);
        if (value.cicloId != 0) {
            setCiclo(value)
        }
    };
    const [ciclo, setCiclo] = useState<ICiclo>(initialStateCiclo)
    const { message, messageAlerta, cicloId, descripcion, mes, colorMes } = ciclo;

    const ApiPlanCompenzacion = async () => {
        handleClickOpenAlert();
        const { data } = await IndexPlanCompenzacionService();
        //modificar datos de entrada para activar modal
        setCiclo({ ...data.data, messageAlerta: verificarModal('PlanCompenzacion') ? '' : data.data.messageAlerta });
        setSpinner(false)
    }

    useEffect(() => {
        if (cicloId == 0) {
            console.log('actualizar ', cicloId)
            ApiPlanCompenzacion();
        }
    }, [mes])

    /*alerta */
    const [openAlert, setOpenAlert] = useState(false);
    const handleClickOpenAlert = () => {
        setOpenAlert(true);
    };
    const handleCloseAlert = () => {
        NoViewAlertModal('PlanCompenzacion', 'false')
        setOpenAlert(false);
    };
    return (
        <ContextPlanCompenzacion.Provider value={{ ciclo, setCiclo }}>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={spinner}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <CBreadcrumbs icon={<DashboardIcon />} nombreRoute={"Tablero"} route={"/inicio"} nombresRoutes={['Bono liderazgo']} routes={['#']}></CBreadcrumbs>
            <Typography variant="subtitle1" component="h6" style={{ fontWeight: 'bold' }} sx={{ pl: 1, pr: 1 }}>
                Bono liderazgo
                <Button variant="contained" href="#outlined-buttons" sx={{ m: 1, textTransform: 'none' }} size='small' onClick={() => setOnModalCiclo(true)} disabled={onModalciclo}>
                    {mes}
                </Button>
            </Typography>
            <Typography variant="body2" gutterBottom sx={{ pl: 1, pr: 1 }}  >
                Resumen de bono liderazgo
            </Typography>
            {
                message != '' ? (<Alert variant="filled" severity={colorMes} sx={{ m: 1 }}>
                    {message}
                </Alert>) : null
            }
            <Grid container item xs={12} alignItems="center" justifyContent="center" >
                <ResumenPlanCompenzacion previewLoad={4} />
            </Grid>
            <br />
            <Typography variant="body2" gutterBottom sx={{ pl: 1, pr: 1 }}>
                Primera generación
            </Typography>
            <Grid container item xs={12} alignItems="center" justifyContent="center" sx={{ p: 1 }}>
                <Grid item xs={12} sm={12}>
                    <DataGridEquipoPrimeraGeneracion />
                </Grid>
            </Grid>
            <br />
            <Typography variant="body2" gutterBottom sx={{ pl: 1, pr: 1 }}>
                Equipos activos (ventas registradas durante el mes)
            </Typography>
            <Grid container spacing={3} alignItems="center" justifyContent="center" sx={{ p: 1 }}>
                <Grid item xs={12} sm={12}>
                    <CEquipo></CEquipo>
                </Grid>
            </Grid>
            <CModalCiclo onClose={handleClose} open={onModalciclo} />
            {
                messageAlerta != '' ?
                    (<ModalAlert
                        message={messageAlerta}
                        onClose={handleCloseAlert}
                        open={openAlert}
                        title='AVISO'
                    />) : null
            }
        </ContextPlanCompenzacion.Provider>
    )
}

export default PlanCompenzacion
