import { Box, Card, CardContent, Dialog, DialogContent, DialogProps, DialogTitle, List, ListItem, ListItemButton, Typography } from '@mui/material'
import React from 'react'
import { ICiclo, IResponseTodosICiclo } from '../../Services/Comisiones/Interfaces/ICiclo';
export interface ModalCicloProps {
    open: boolean;
    selectedValue: ICiclo;
    onClose: (value: ICiclo) => void;
    listCiclo: ICiclo[]
}
const ModalCiclo = (props: ModalCicloProps) => {
    const [scroll, setScroll] = React.useState<DialogProps['scroll']>('paper');
    const { onClose, selectedValue, open } = props;

    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = (value: ICiclo) => {
        
        onClose(value);
    };
    return (
        <Dialog onClose={handleClose}
            open={open}
            scroll={scroll}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <DialogTitle component="div" variant="subtitle1" align="center">
                Selecione un ciclo
                <Typography variant='subtitle2' >Informacion de todos los ciclos hasta la fecha</Typography>
            </DialogTitle>

            <DialogContent>
                <List>
                    {props.listCiclo.map((ciclo, i) => (
                        <ListItem disableGutters key={i} >
                            <ListItemButton
                                onClick={() => {
                                    handleListItemClick(ciclo)
                                }}
                                key={i}>

                                        <Box style={{ width: '100%' }} >
                                                <Typography
                                                    variant="body2"
                                                    sx={{
                                                        m: 0,
                                                        "&:hover": {
                                                            background: "linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(197,197,197,1) 100%)",
                                                        }
                                                    }}
                                                    align={'center'}
                                                    style={{ fontWeight: 'bold', cursor: 'pointer' }}
                                                >
                                                    {ciclo.mes}
                                                </Typography>
                                                <hr />
                                                <div>
                                                    <Typography variant="body2" align={'center'}>
                                                        {ciclo.descripcion}
                                                    </Typography>
                                                </div>
                                        </Box>
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </DialogContent>
        </Dialog>
    )
}

export default ModalCiclo
