import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { lazy, Suspense, useEffect, useState } from 'react';
import { ProtectorRoute } from '../Components/ProtectorRoute';
import { useDispatch, useSelector } from 'react-redux';
import { SelectToken, setToken } from '../Reducers/Slices/LoginSlice';
import { Error404 } from '../Pages/Error404/Error';
import { Backdrop, CircularProgress } from '@mui/material';
import { Authenticacion } from '../Services/Comisiones/Authenticacion';
import { LibresRoute } from '../Components/LibresRoute';
import { AutenticacionDto } from '../Services/Comisiones/Interfaces/Authenticacion';
// When using TypeScript 4.x and above
import type { } from '@mui/lab/themeAugmentation';
import PlanCompenzacion from '../Pages/PlanCompenzacion/PlanCompenzacion';
export const mdTheme = createTheme(
    {
        components: {
            MuiTimeline: {
                styleOverrides: {
                    root: {
                        backgroundColor: 'red',
                    },
                },
            },
        },
        palette: {
            primary: {
              main: "rgb(46, 54, 150)"
            }
          }
    }
);

const Login = lazy(() => import('../Pages/Login/login'));
const Home = lazy(() => import('../Pages/Home/home'));
const MiRed = lazy(() => import('../Pages/MiRed/miRed'));

const TopVendedores = lazy(() => import('../Pages/TopVendedores/TopVendedores'));
const EnMantenimiento = lazy(() => import('../Pages/Mantenimiento/EnMantenimiento'));
const Vendedores = lazy(() => import('../Pages/Vendedores/Vendedores'));
const VentaResidual = lazy(() => import('../Pages/VentaResidual/VentaResidual'));
const TopVendedor = lazy(() => import('../Pages/TopVendedores/TopVendedores'));
const UbicacionRed = lazy(() => import('../Pages/UbicacionRed/UbicacionRed'));
const Ascenso = lazy(() => import('../Pages/Ascenso/Ascenso'));
const Perfil = lazy(() => import('../Pages/Perfil/Perfil'));
const MonteSion = lazy(() => import('../Pages/MonteSion/MonteSion'));
const Incentivos = lazy(() => import('../Pages/Incentivos/Incentivos'));
const VentaPersonal = lazy(() => import('../Pages/VentaPersonal/VentaPersonal'));
const VentaGrupo = lazy(() => import('../Pages/VentaGrupo/VentaGrupo'));
const MapasMiRed = lazy(() => import('../Pages/MiRedMapas/MiRedMapas'));
const initialState: AutenticacionDto = {
    modulos: [],
    nombreCompleto: '',
    perfil: ''
}
export const Navigation = () => {
    const token = useSelector(SelectToken);
    const [user, setUser] = useState(initialState);
    const [spinner, setSpinner] = useState(true);
    const dispatch = useDispatch();
    const updateToken = (token: boolean) => {
        dispatch(
            setToken({
                token: token
            })
        )
    }
    const getAuth = async () => {
        try {
            const { data } = await Authenticacion();
            if (data.status == 1) {
                setUser(data.data);
            }
            else {
                updateToken(false);
            }
        } catch (error) {
            updateToken(false);
        }
    };
    useEffect(() => {
        if (token) {
            getAuth();
        } else {
            setSpinner(false)
        }
        return () => {
            setSpinner(false)
        }
    }, [token]);


    return (

        <Suspense fallback={
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={spinner}>
                <CircularProgress color="inherit" />
            </Backdrop>
        }>
            <BrowserRouter>
                <ThemeProvider theme={mdTheme}>
                    <Routes>
                        <Route element={
                            <ProtectorRoute valid={token} children redirrecTo={'/login'} nombreCompleto={user.nombreCompleto} perfil={user.perfil} />
                        }>
                            <Route path="/perfil" element={<Perfil />}></Route>
                            <Route path="/inicio" element={<Home />}></Route>
                            <Route path="/mi-red" element={<MiRed />}></Route>
                            <Route path="/vendedores" element={<TopVendedores />}></Route>
                            <Route path="/vendedores-mes" element={<Vendedores />}></Route>
                            <Route path="/ascensos" element={<Ascenso />}></Route>
                            <Route path="/ubicacion-red" element={<UbicacionRed />}></Route>
                            <Route path="/monte-sion" element={<MonteSion />}></Route>
                            <Route path="/incentivos" element={< Incentivos />}></Route>
                            <Route path="/plan-compenzacion" element={< PlanCompenzacion />}></Route>
                            <Route path="/venta-personal" element={< VentaPersonal />}></Route>
                            <Route path="/venta-grupo" element={< VentaGrupo />}></Route>
                            <Route path="/venta-residual" element={< VentaResidual />}></Route>
                            <Route path="/mejor-vendedor" element={< TopVendedor />}></Route>
                            <Route path="/mi-red-mapa" element={< MapasMiRed />}></Route>
                        </Route>
                        <Route path='/login' element={
                            <LibresRoute valid={token} redirrecTo={'/inicio'}>
                                <Login />
                            </LibresRoute>
                        }>
                            <Route path="/login" element={<Login />}></Route>

                        </Route>
                        <Route path="/en-mantenimiento" element={<EnMantenimiento />}></Route>
                        <Route path="/" element={<Navigate to="/login" replace />}></Route>
                        <Route path="/Error" element={<Error404 />}></Route>
                        {/* redirrect */}
                        <Route path='/*' element={<Navigate to="/login" replace />}>
                        </Route>
                    </Routes>
                </ThemeProvider>
            </BrowserRouter>
        </Suspense>

    )
}