import axios from 'axios';
import { AxiosRequest, AxiosResponse } from '../../Utils/Axios';
import { IResponseCardMonteSion } from './Interfaces/MonteSion';
import { IResponseDataGridEquipos, IResponseDataGridPrimeraGeneracion, IResponseIPlanCompenzacion } from './Interfaces/PlanCompenzacion';

//llamada a interceptor
AxiosRequest();
AxiosResponse();
export async function IndexPlanCompenzacionService(cicloId: number = 0) {
    return await axios.get<IResponseIPlanCompenzacion>(`${process.env.REACT_APP_API_COMISIONES}api/plan-compenzacion?cicloId=${cicloId}`);
}
export async function ResumenPlanCompenzacionService(cicloId: number = 0) {
    return await axios.get<IResponseCardMonteSion>(`${process.env.REACT_APP_API_COMISIONES}api/plan-compenzacion/tarjeta?cicloId=${cicloId}`);
}
export async function DataGridEquipoServices(cicloId: number = 0) {
    return await axios.get<IResponseDataGridPrimeraGeneracion>(`${process.env.REACT_APP_API_COMISIONES}api/plan-compenzacion/data-grid-equipos-primera-generacion?cicloId=${cicloId}`);
}
export async function DataGridEquipoTodosServices(cicloId: number = 0) {
    return await axios.get<IResponseDataGridEquipos>(`${process.env.REACT_APP_API_COMISIONES}api/plan-compenzacion/data-grid-equipos?cicloId=${cicloId}`);
}