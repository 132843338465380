import { browserName, osName, browserVersion } from 'react-device-detect';
interface getDataDevicePros {
    browserName: string,
    browserVersion: string,
    osName: string
}
export const getDataDevice = (): getDataDevicePros => {
    let device: getDataDevicePros = {
        browserName: browserName,
        browserVersion: osName,
        osName: browserVersion
    }
    return device;
}